<template>
    <v-row>
        <v-col md="3" class="py-0">
            <v-text-field
                type="text"
                label="Adresse email"
                dense
                @change="debouncedMailSetter($event)"
                @blur="debouncedMailSetter($event)"
                :value="coworker.coworkerEmail"
                :rules="[rules.required, rules.email]"
                class="rounded-xl"
                outlined
            ></v-text-field>
        </v-col>
        <template v-if="coworker.User && !loading">
            <v-col md="3" class="py-0">
                <v-text-field
                    type="text"
                    label="Nom du collaborateur"
                    dense
                    :value="coworker.User.firstName + ' ' + coworker.User.lastName"
                    readonly
                    class="rounded-xl"
                    hide-details
                    outlined
                ></v-text-field>
            </v-col>
            <v-col md="3" class="py-0">
                <v-text-field
                    type="text"
                    label="Poste occupé"
                    dense
                    :value="coworker.User.jobName"
                    readonly
                    class="rounded-xl"
                    hide-details
                    outlined
                ></v-text-field>
            </v-col>
            <v-col md="3" class="py-0">
                <v-row>
                    <v-col md="9">
                        <v-text-field
                            type="text"
                            label="N° de téléphone"
                            dense
                            :value="coworker.User.workPhone"
                            readonly
                            class="rounded-xl"
                            hide-details
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col md="3">
                        <v-btn icon @click="deleteCoworker"><v-icon>fas fa-trash</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </template>
        <template v-else>
            <v-col md="9" class="py-0">
                <v-row>
                    <v-col md="11">
                        <v-btn
                            v-if="coworker.coworkerEmail"
                            rounded style="width: 100%"
                            class="blue"
                            :loading="loading"
                            @click="sendInvite"
                        >
                            Envoyer un mail d'invitation
                        </v-btn>
                    </v-col>
                    <v-col md="1">
                        <v-btn icon rounded @click="deleteCoworker"><v-icon>fas fa-trash</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </template>
    </v-row>
</template>

<script>
import { debounce } from "debounce";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
    name: "CoworkerLine",

    props: {
        coworkerId: {
            type: String,
            require: true,
        },
    },

    data() {
        return {
            loading: false,
            rules: {
                required: value => !!value || "Champ requis.",
                email: value => {
                    if (value === this.user.email) return "Vous ne pouvez pas vous ajoutez en tant que collaborateur";

                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || "Adresse mail invalide.";
                },
            },
        }
    },

    created() {
        this.debouncedMailSetter = debounce(function(value) {
            this.update(value);
        }, 1000);
    },

    computed: {
        ...mapState("settings", [
            "coworkers",
            "user",
        ]),

        coworker: function() {
            return this.getCoworkerById()(this.coworkerId);
        }
    },

    methods: {
        ...mapActions("settings", [
            "setCoworker",
            "getCoworkers",
            "sendInviteMail",
            "removeCoworker",
        ]),

        ...mapGetters("settings", [
            "getCoworkerById",
        ]),

        update: async function(value) {
            value = value.target ? value.target.value : value;

            if(this.rules.email(value) !== true) return;

            this.loading = true;
            await this.setCoworker({ id: this.coworkerId, coworkerEmail: value });
            await this.getCoworkers();
            this.loading = false;
        },

        deleteCoworker: async function() {
            await this.removeCoworker(this.coworkerId);
            await this.getCoworkers();
        },

        sendInvite: async function() {
            await this.sendInviteMail(this.coworkerId);
        },
    }
}
</script>

<style scoped>

</style>