<template>
    <v-container fluid>
        <page-banner links="Profil"></page-banner>

        <p class="text-h6">Mes informations</p>
        <v-row>
            <v-col md="6">
                <v-card elevation="1" class="pa-5">
                    <v-text-field
                        type="text"
                        label="Nom de famille"
                        :value="user.lastName"
                        @change="debouncedSetter('lastName', $event)"
                        dense
                        class="rounded-xl"
                        outlined
                    ></v-text-field>
                    <v-text-field
                        type="text"
                        label="Prénom"
                        :value="user.firstName"
                        @change="debouncedSetter('firstName', $event)"
                        dense
                        class="rounded-xl"
                        outlined
                    ></v-text-field>
                    <v-text-field
                        type="text"
                        label="Poste occupé"
                        :value="user.jobName"
                        @change="debouncedSetter('jobName', $event)"
                        dense
                        class="rounded-xl"
                        outlined
                        hide-details
                    ></v-text-field>
                </v-card>
            </v-col>
            <v-col md="6">
                <v-card>

                </v-card>
            </v-col>
        </v-row>

        <p class="text-h6 mt-12">Mon entreprise / Organisation</p>
        <v-row>
            <v-col>
                <v-card elevation="1" class="pa-5">
                    <v-row>
                        <v-col md="6">
                            <v-text-field
                                type="text"
                                label="Entreprise / Organisation"
                                :value="user.companyName"
                                @change="debouncedSetter('companyName', $event)"
                                dense
                                class="rounded-xl"
                                outlined
                            ></v-text-field>
                            <v-text-field
                                type="text"
                                label="Téléphone de bureau"
                                :value="user.workPhone"
                                @change="debouncedSetter('workPhone', $event)"
                                dense
                                class="rounded-xl"
                                outlined
                            ></v-text-field>
                            <v-text-field
                                type="text"
                                label="Téléphone mobile"
                                :value="user.mobilePhone"
                                @change="debouncedSetter('mobilePhone', $event)"
                                dense
                                class="rounded-xl"
                                outlined
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col md="6">
                            <v-text-field
                                type="text"
                                label="Adresse"
                                :value="user.address"
                                @change="debouncedSetter('address', $event)"
                                dense
                                class="rounded-xl"
                                outlined
                            ></v-text-field>
                            <v-text-field
                                type="text"
                                label="Ville"
                                :value="user.city"
                                @change="debouncedSetter('city', $event)"
                                dense
                                class="rounded-xl"
                                outlined
                            ></v-text-field>
                            <v-text-field
                                type="text"
                                label="Pays"
                                :value="user.country"
                                @change="debouncedSetter('country', $event)"
                                dense
                                class="rounded-xl"
                                outlined
                                hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <template v-if="user.subscriptionType === 2">
            <p class="text-h6 mt-12">Mes collaborateurs</p>
            <v-row>
                <v-col>
                    <v-card elevation="1" class="pt-5">
                        <v-card-text>
                            <coworker-line v-for="coworker in coworkers" :key="coworker.id" :coworker-id="coworker.id"></coworker-line>
                            <v-row v-if="coworkers.length < 5">
                                <v-col>
                                    <v-btn block outlined plain @click="clickAddCoworker"><v-icon>fas fa-plus</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
import PageBanner from "@/components/PageBanner";
import { mapActions, mapState } from "vuex";
import { debounce } from "debounce";
import CoworkerLine from "../../components/CoworkerLine";

export default {
    name: "Profile",

    components: { CoworkerLine, PageBanner },

    data: () => ({

    }),

    created() {
        this.refreshToken();
        this.getCoworkers();

        this.debouncedSetter = debounce(function(name, value) {
            this.update(name, value);
        }, 1000);
    },

    computed: {
        ...mapState("settings", ["user", "coworkers"]),
    },

    methods: {
        ...mapActions("settings", [
            "refreshToken",
            "modifyUser",
            "addCoworker",
            "getCoworkers",
        ]),

        update: async function(name, value) {
            const updatedObject = {
                [name]: value,
            };

            await this.modifyUser(updatedObject);
            await this.refreshToken();
        },

        clickAddCoworker: async function() {
            await this.addCoworker();
            await this.getCoworkers();
        }
    },
}
</script>

<style scoped>

</style>